/** Author: Draco Chan < draco.chan@butterfly.com.au / im@dracochan.com >
 * Simple lock width and height
 * Version: 1.2
 * Known issue: Can't get width properly if the element is hidden. Just call the function every time the element becomes visible
 * Requirement: jQuery 1.8+
 * Usage:
jQuery(document).ready(function() {
	jQuery('.lockratio').lockRatio('width', (400/200));
});
 * Parameters:
basedOn: 'width'(default) or 'height'
ratio: (width/height)
rangeMin (optional): The script will be disabled if window is smaller than this.
rangeMax (optional): The script will be disabled if window is larger than this.
 **/
 

jQuery.fn.lockRatio = function(basedOn, ratio, rangeMin, rangeMax){
	return this.each(function() { 
		var el = jQuery(this);

		var includeOuter = (el.css('box-sizing').toLowerCase()=='border-box');
		
		if( basedOn===undefined ){
			basedOn = 'width';
		} else {
			basedOn = basedOn.toLowerCase();	
		}
		if( ratio===undefined ){
			ratio = includeOuter? (el.outerWidth()/el.outerHeight()) : (el.width()/el.height());
		}
		
		var resized = false;
		var resizeIt = function () {
			var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

			if((rangeMin!==undefined && width<rangeMin) || (rangeMax!==undefined && width>rangeMax)) {
				if(resized) {
					resized = false;
					el.css('width', '');
					el.css('height', '');
				}
				return true;
			}
			
			switch(basedOn) {
				case 'width':
					el.css('height', ((includeOuter?el.outerWidth():el.width())/ratio)+'px');
					break;
					
				case 'height':
					el.css('width', ((includeOuter?el.outerHeight():el.height())*ratio)+'px');
					break;	
			}
			resized = true;
		};
		if(typeof el.attr('lockratio') !== "undefined") {
			jQuery(window).unbind(el.attr('lockratio'));
		}
		var eventID = 'resize'+'.'+Math.random().toString(36).substring(7);
		el.attr('lockratio', eventID);
		jQuery(window).bind(eventID, function(){
			setTimeout(resizeIt, 50); /*avoid buggy browser maximise button*/
		});
		resizeIt();
	});
};